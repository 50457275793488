import React from 'react';
import signature from '../../../imgs/signature.png';
import FloatingShape from '../floating_shape';
import './style.css';
import { Link } from 'react-router-dom';

function LandingPage1() {
    return (
        <div className='landing-page-1'>
            <div className='landing-page-1-bg'>
                <FloatingShape></FloatingShape>
            </div>
            <div className='left-section'>
                <h4>Software Developer</h4>
                <img src={signature} alt="signature"/>
                <p>3rd year student studying Computer Science Specialist at the University of Toronto</p>
            </div>
            <div className='right-section'>
            </div>
            <div className='landing-page-1-footer'>
                <p>Responsive 3D background is adapted from this <a href="https://blog.olivierlarose.com/tutorials/3d-float-effect">tutorial</a>. Credited: Olivier Larose</p>
            </div>
        </div>
    )
}

export default LandingPage1;
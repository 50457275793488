import React from 'react';
import './style.css';
import UofT from '../../../imgs/UofT.webp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {motion} from 'framer-motion';

function LandingPage4() {
    return (
        <div className='landing-page-4'>
            <div className='landing-4__left'>
                <h4>David Li (Li Li)</h4>
                <p>Third-year Computer Science Specialist focusing on Artificial Intelligence,
                    Computer Vision and Web & Internet Technologies at the University of Toronto,
                    actively enrolled in the ASIP Co-op program. Possesses hands-on expertise in 
                    web, software development, and Algorithm implementation and optimization.
                    Currently seeking for 12-16 month internship</p>
                
                <h5>Education</h5>
                <p>Computer Science Specialist, University of Toronto, Toronto, ON, Canada</p>
                <p>Aug, 2021 - Present</p>
                <ul>
                    <li>Expected Graduation: June 2026</li>
                    <li>Dean's List for Academic Excellence (2021-2022)</li>
                </ul>
            </div>
            <div className='landing-4__right'>
                <div className='landing-4__img'>
                    <img src={UofT} alt="Image of University of Toronto" />
                </div>
                <div className='landing-4__img-help'>
                    <HelpOutlineIcon />
                    <motion.button>Why there is no personal photo</motion.button>
                </div>
            </div>
        </div>
    )
}

export default LandingPage4
import React from 'react'
import './style.css'
import LinkButton from './link_buttom'
import errorImage from '../../../imgs/Project Shamazify.png'
import { useState, useEffect } from 'react'
import { ajax } from '../../../ajax'
import { AnimatePresence, MotionConfig, motion } from 'framer-motion'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

/* 
Page indicator part of this code is modified based on this tutorial:
https://framerbook.com/animation/example-animations/17-page-indicators/
*/

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

const imageVariants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 600 : -600,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 600 : -600,
            opacity: 0
        };
    }
};

function LandingPage3() {
    const [[currPage, direction], setCurrPage] = useState([0, 0]);
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const paginate  = (direction) => {
        var targetPage = (currPage + direction) % projects.results.length;
        if (targetPage < 0) targetPage = projects.results.length - 1;
        setCurrPage([targetPage, direction]);
    }

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                setIsLoading(true);
                const response = await ajax('/project/projects/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok){
                    throw new Error('Failed to fetch projects');
                }
                const data = await response.json();
                setProjects(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchProjects();
    }, []);

    const getCurrentProject = () => {
        if (error || !projects.results) {
            return {title: "Error connect to backend server", start_date: "", end_date: "", group_members_count: "", description: "", image: "", github: ""};
        }
        return projects.results[currPage];
    }

    return (<>
        <div className='landing3__left'>
            <div className='landing3__header'>
                <h4>
                    Project Details
                </h4>
            </div>
            <div className='landing3__image'>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.img 
                        src={getCurrentProject().image} 
                        alt="Project Image"
                        key={currPage}
                        variants={imageVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                          }}
                        custom={direction}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, {offset, velocity}) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) {
                                paginate(1);
                            } else if (swipe > swipeConfidenceThreshold){
                                paginate(-1);
                            }
                        }}
                        />
                </AnimatePresence>
                <div className='landing3__image-prev' onClick={() => paginate(-1)}>
                    <ArrowLeftIcon/>
                </div>
                <div className='landing3__image-next' onClick={() => paginate(1)}>
                    <ArrowRightIcon/>
                </div>
                <div className='landing3__page-indicator'>
                    {projects.results ? projects.results.map((index) => {
                        var id = index.id;
                        return (
                            <motion.div
                                animate={{
                                    opacity: currPage === id - 1 ? 1 : 0.5,
                                }}
                                onTap={() => setCurrPage([id - 1, id - 1 - currPage])}
                                key={index}
                                className='landing3__page-indicator-dot'
                            />
                        );
                    }) : <></>}
                </div>
            </div>
            <div className='landing3__descriptions_name'>
                <h5>
                    {getCurrentProject().title}
                </h5>
            </div>
            <div className='landing3__descriptions_details'>
                <table>
                    <tr>
                        <td>Start Time:</td>
                        <td>{getCurrentProject().start_date}</td>
                    </tr>
                    <tr>
                        <td>End Time:</td>
                        <td>{getCurrentProject().end_date}</td>
                    </tr>
                    <tr>
                        <td>Group Member:</td>
                        <td>{getCurrentProject().group_members_count}</td>
                    </tr>
                </table>
            </div>
            <div className='landing3__link'>
                <LinkButton status={true} description="Github" link={getCurrentProject().github}/>
                <LinkButton status={false} description="Detail Page"/>
            </div>
        </div>
        <div className='landing3__right'>
            <div className='landing3__text_header'>
                <h5>Desriptions</h5>
            </div>
            <div className='landing3__text'>
                {getCurrentProject().description}
            </div>
        </div>
    </>)
}

export default LandingPage3
import './style.css';
import ParallaxText from '../ParallaxText';

function Popup({ children, title, isOpen, onClose, width = '60%', height = '60%', top = '20%', left = '20%', image, fastClose }) {
    if (!isOpen) return null;
  
    return (
      <div 
        className='popup'
        onClick={fastClose ? onClose : null}>
        <div 
        className='popup__content'
        style={{
          width: width,
          height: height,
          top: top,
          left: left,
        }}
        onClick={(e) => e.stopPropagation()}>
          <div className='popup__title'>
            <ParallaxText baseVelocity={2} fontSize={65}>{title}</ParallaxText>
            <div className='popup__title-filter'></div>
            <div className='popup__title-icon'>
              <img src={image} alt='popup title icon'/>
            </div>
          </div>
          <div className='popup__main'>
            {children}
          </div>
        </div>
      </div>
    );
  }

export default Popup;
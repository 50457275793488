import React from 'react'
import './style.css'
import {motion, progress} from 'framer-motion'

function SkillBox({children, skills, proficiency_level}) {
    const progressBarVariants = (proficiency_level) => {
        const color = proficiency_level < 3 ? '#e88b8b' 
        : proficiency_level < 4 ? '#e7e88b' 
        : proficiency_level < 5 ? '#c1e88b' 
        : '#8be891';

        return {
            hidden: {
                width: 0,
                opacity: 0,
                transition: {
                    type: 'spring',
                    duration: 0.5,
                    delay: 0.3
                }
            },
            visible: {
                width: `${proficiency_level * 20}%`,
                backgroundColor: color,
                opacity: 1,
                transition: {
                    type: 'spring',
                    duration: 0.5,
                    delay: 0.3
                }
            }
        }
    }

    if (skills === undefined || proficiency_level === undefined) {
        skills = [];
        proficiency_level = [];
    }
    return <div className='skillBox'>
        {children}
        {skills.map((skill, index) => {
            return <div className='skill'>
                <div className='skill__name'>
                    <p>{skill}</p>
                </div>
                <div className='skill__bar'>
                    <motion.div className='skill__bar__progress' 
                        variants={progressBarVariants(proficiency_level[index])} 
                        initial='hidden' 
                        whileInView="visible"
                        viewport={{once: true, amount: 0.8}}>
                    </motion.div>
                </div>
            </div>
        })}
    </div>
}

export default SkillBox
import React from 'react';
import SkillBox from './SkillBox';
import './style.css';

function LandingPage2() {
    const web_development_skills = ['JavaScript', 'Django', 'Figma', 'HTML', 'CSS', 'React', 'Framer Motion', 'Bootstrap']
    const web_development_prof = [5, 5, 4, 4, 4, 3, 3, 2]
    const software_development_skills = ['C', 'Python', 'Java', 'C#', 'C++', 'R', 'Unix cmd', 'Clearn Architecture']
    const software_development_prof = [5, 5, 5, 4, 3, 3, 3, 5]
    const ml_stats_skills = ['Pandas', 'NumPy', 'Scikit-Learn', 'Matplotlib', 'Neural Networks', 'Prob Model', 'RL', 'PCA']
    const ml_stats_prof = [4, 4, 4, 4, 0, 0]
    const software_debug_skills = ['gdb', 'valgrind', 'pdb', 'JUit', 'Postman']
    const software_debug_prof = [5, 5, 5, 5, 4]
    const database_skills = ['PostgreSQL', 'MySQL', 'SQLite']
    const database_prof = [4, 4, 3]
    const others_skills = ['Git', 'Latex', 'Unix', 'Docker', 'Unity', 'Microsoft Office']
    const others_prof = [5, 4, 4, 4, 3, 5]
    
    return (
        <div className='landing-page-2'>
            <div className='landing-page-2__header'>
                <h4>My Skill Sets</h4>
            </div> 
            <div className='landing-page-2__cards'>
                <SkillBox skills={web_development_skills} proficiency_level={web_development_prof}>
                    <h5>Web Development</h5>
                </SkillBox>
                <SkillBox skills={software_development_skills} proficiency_level={software_development_prof}>
                    <h5>Software Developement</h5>
                </SkillBox>
                <SkillBox skills={ml_stats_skills} proficiency_level={ml_stats_prof}>
                    <h5>Machine Learning</h5>
                </SkillBox>
                <SkillBox skills={software_debug_skills} proficiency_level={software_debug_prof}>
                    <h5>Software Debugging</h5>
                </SkillBox>
                <SkillBox skills={database_skills} proficiency_level={database_prof}>
                    <h5>Database</h5>
                </SkillBox>
                <SkillBox skills={others_skills} proficiency_level={others_prof}>
                    <h5>Others</h5>
                </SkillBox>
            </div>
        </div>
    )
}

export default LandingPage2;
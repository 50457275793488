import './style.css';
import React from 'react';
import { useLocation } from 'react-router-dom'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '45vw',
  height: '70vh',
  marginLeft: '5vw',
};
const center = {
  lat: 43.6532,
  lng: -79.3832,
};

function Contact(){
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAy2ZDbUYiUh98bmId6O9fw-NIjiqRX0JY',
        libraries,
    });
    
    if (loadError) {
        return <div>Error loading maps</div>;
    }
    
    return (
        <>
            <div className='contact'>
                <div className='contact__header'>
                    <div className='contact__header-title'>
                        <h3>Contact Me</h3>
                    </div>
                    <div className='contact__options'> 
                        <div className='contact__options-phone'>
                            <div className='contact__label'>
                                <PhoneIcon/>
                            </div>
                            <p className='contact__label-text'>Phone</p>
                        </div>
                        <div className='contact__options-email'>
                            <div className='contact__label'>
                                <EmailIcon/>
                            </div>
                            <p className='contact__label-text'>Email</p>
                        </div>
                        <div className='contact__options-live'>
                            <div className='contact__label'>
                                <HeadsetMicIcon/>
                            </div>
                            <p className='contact__label-text'>Live Chat (beta)</p>
                        </div>
                    </div>
                    <div className='contact__information'>
                        <div className='contact__phone'>
                            
                        </div>
                        <div className='contact__email'>
                            <p>utdavid.Li@mail.utoronto.ca</p>
                        </div>
                        <div className='contact__live'>

                        </div>
                    </div>
                </div>

                <div className='contact__main'>
                    <div className='contact__main-left'>
                        {isLoaded ? <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={10}
                            center={center}
                        >
                            <Marker position={center} />
                        </GoogleMap> : <></>}
                    </div>
                    <div className='contact__main-right'>
                        <div className='contact__form-header'>
                            <h4>Send me a message</h4>
                        </div>
                        <div className='contact__form'>
                            <form>
                                <div className='contact__form-name-row'>
                                    <div className='contact__form-first-name'>
                                        <label htmlFor='firstName'>First Name</label>
                                        <input type='text' id='firstName' name='firstName' placeholder='Your first name...'></input>
                                    </div>
                                    <div className='contact__form-last-name'>
                                        <label htmlFor='lastName'>Last Name</label>
                                        <input type='text' id='lastName' name='lastName' placeholder='Your last name...'></input>
                                    </div>
                                </div>
                                <div className='contact__form-email'>
                                    <label for='email'>Email</label>
                                    <input type='text' id='email' name='email' placeholder='Your email...'></input>
                                </div>
                                <div className='contact__form-company'>
                                    <label for='subject'>Company name</label>
                                    <input type='text' id='subject' name='subject' placeholder='Company name'></input>
                                </div>
                                <div className='contact__form-subject'>
                                    <label for='subject'>Subject</label>
                                    <input type='text' id='subject' name='subject' placeholder='Subject...'></input>
                                </div>
                                <div className='contact__form-message'>
                                    <label for='message'>Message</label>
                                    <textarea id='message' name='message' placeholder='Write something...'></textarea>
                                </div>
                                <div className='contact__form-submit'>
                                    <input type='submit' value='Submit'></input>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default Contact;
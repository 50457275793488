/**
 * Code modifed based on the following source:
 * 3D Float Effect: Build a 3D Float Effect using Three.js, Framer Motion, Next.js
 * Link: https://blog.olivierlarose.com/tutorials/3d-float-effect
 * Credit: Olivier Larose
 */

import { Canvas } from '@react-three/fiber'
import { Environment, useGLTF, Float } from '@react-three/drei'
import { motion } from 'framer-motion-3d'
import { useMotionValue, useSpring, useTransform  } from "framer-motion"
import { useEffect, React } from 'react'

function FloatingBackground() {
    const { nodes } = useGLTF("/medias/floating_shapes.glb");

    const mouse = {
        x: useMotionValue(0),
        y: useMotionValue(0)
      }
    
      const smoothMouse = {
        x: useSpring(mouse.x, {stiffness: 75, damping: 100, mass: 3}),
        y: useSpring(mouse.y, {stiffness: 75, damping: 100, mass: 3})
      }
    
      const manageMouse = e => {
        const { innerWidth, innerHeight } = window;
        const { clientX, clientY } = e;
        const x = clientX / innerWidth
        const y = clientY / innerHeight
        mouse.x.set(x);
        mouse.y.set(y);
      }
    
      useEffect( () => {
        window.addEventListener("mousemove", manageMouse)
        return () => window.removeEventListener("mousemove", manageMouse)
      }, [])
    
    

    return (
        <Canvas style={{background: "#e0e0e2"}} orthographic camera={{position: [0, 0, 250], zoom: 7}}>
            <Float 
            floatIntensity={0.5}
            rotationIntensity={0.5}
            speed={0.5}
            floatingRange={[-0.05, 0.05]}
            >
            <group>
                <Mesh node={nodes.Sphere001} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Sphere002} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cylinder002} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Sphere003} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cylinder003} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cylinder005} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cube002} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cylinder006} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cylinder007} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Cylinder009} multiplier={2.4} mouse={smoothMouse}/>
                <Mesh node={nodes.Sphere} multiplier={2.4} mouse={smoothMouse}/>
            </group>
            </Float>
            <Environment preset="studio"/>
        </Canvas>
    )   
}

export default FloatingBackground;

useGLTF.preload("/medias/floating_shapes.glb");

function Mesh({node, multiplier, mouse}) {
    const { geometry, material, position, scale, rotation } = node;
    const a = multiplier / 2;
    const rotationX = useTransform(mouse.x, [0,1], [rotation.x - a, rotation.x + a]);
    const rotationY = useTransform(mouse.y, [0,1], [rotation.y - a, rotation.y + a]);
    const positionX = useTransform(mouse.x, [0,1], [position.x - multiplier * 2, position.x + multiplier * 2]);
    const positionY = useTransform(mouse.y, [0,1], [position.y + multiplier * 2, position.y - multiplier * 2]);
    return (
      <motion.mesh
          castShadow={true}
          receiveShadow={true}
          geometry={geometry}
          material={material}
          position={position}
          rotation={rotation}
          rotation-y={rotationX}
          rotation-x={rotationY}
          position-x={positionX}
          position-y={positionY}
          scale={scale}
      />
    )
  }
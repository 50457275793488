import React from 'react';
import './style.css';
import resume from '../../../pdfs/resume.pdf';

function LandingPage5() {
    return (
        <div className='landing-page-5'>
            <h4 className="landing-5-header">My Resume</h4>
            <iframe src={resume} width='80%' height='90%'/>
        </div>
    )
}

export default LandingPage5;
import { useEffect, useRef, useState } from 'react';
import './style.css';
import {
  motion,
  useScroll,
  useMotionValueEvent
} from "framer-motion";
import React from 'react';
import LandingPage1 from './landing_1';
import LandingPage2 from './landing_2';
import LandingPage3 from './landing_3';
import LandingPage4 from './landing_4';
import LandingPage5 from './landing_5';
import HelpIcon from './help_icon';
import ScrollProgress from '../../components/ScrollProgress';
import Popup from '../../components/PopUp';
import slime from '../../imgs/Slime.png';
import { ajax } from '../../ajax';
import LiveChat from '../../components/LiveChat';

function Landing() {
    const totalPages = 5;
    const [currentPage, setCurrentPage] = useState(0);
    const { scrollYProgress } = useScroll();
    const pageRefs = useRef([]);
    const [chatOpen, setChatOpen] = useState(false);
    const pageName = ['INTRODUCTION', 'SKILL SETS', 'MY PROJECTS', 'ABOUT ME', 'MY RESUME']

    pageRefs.current = Array.from({ length: totalPages }, (_, i) => pageRefs.current[i] ?? React.createRef());

    // Subscribe to scrollYProgress changes
    useMotionValueEvent(scrollYProgress, "change", value => {
      var newPage = Math.round(value * (totalPages - 1));
      if (currentPage != newPage) {
        setCurrentPage(newPage);
      }
    });

    // Function to scroll to a page
    const scrollToPage = (pageId) => {
      const ref = pageRefs.current[pageId];
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // IF this is the first time access the website, create a guest account
    useEffect(() => {
      const registerGuest = async () => {
        if (localStorage.getItem('token') === null) {
          try {
              const response = await ajax('/account/create_guest/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
              throw new Error('Failed to create guest account');
            }
            const account_data = await response.json();
            var token = account_data.access;
            var username = account_data.username;
            localStorage.setItem('token', token);
            localStorage.setItem('username', username);
          } catch (error) {
            console.log(error);
          }
        }
      }
      registerGuest();
    }, []);

    return (
      <div className='main-page'>
        <section className='page' ref={pageRefs.current[0]}><LandingPage1></LandingPage1></section>
        <section className='page' ref={pageRefs.current[1]}><LandingPage2></LandingPage2></section>
        <section className='page' ref={pageRefs.current[2]}><LandingPage3></LandingPage3></section>
        <section className='page' ref={pageRefs.current[3]}><LandingPage4></LandingPage4></section>
        <section className='page' ref={pageRefs.current[4]}><LandingPage5></LandingPage5></section>
        <ScrollProgress scrollToPage={scrollToPage} currentPage={currentPage} totalPages={totalPages} pageName={pageName}/>
        <div className='main-page-help'>
          <HelpIcon clickEvent={() => setChatOpen(true)}/>
        </div>
        <Popup 
        title='LiveChat' 
        isOpen={chatOpen} 
        onClose={() => setChatOpen(false)} 
        width='45%' height='90%' top='7%' left='15%' 
        fastClose={true}
        image={slime}>
          <div>
            <LiveChat/>
          </div>
        </Popup>
      </div>
    );
}

export default Landing;
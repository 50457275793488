import './style.css';
import ParallaxText from '../../../components/ParallaxText';

function HelpIcon({clickEvent}) {
    return (
        <div onClick={clickEvent} className='help-icon'>
            <ParallaxText baseVelocity={2}>DAVID</ParallaxText>
            <ParallaxText baseVelocity={-2}>HELP</ParallaxText>
        </div>
    );
}

export default HelpIcon;
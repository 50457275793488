import './style.css';
import { useEffect, useRef, useState } from 'react';
import { ajax } from '../../ajax';
import GPTicon from '../../imgs/chatgpt-icon.webp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function formatTimestamp(timestamp) {
    const messageDate = new Date(timestamp);
    const now = new Date();
    const diffMillis = now - messageDate;
    
    const diffMins = Math.floor(diffMillis / 60000);
    
    if (diffMins < 60) {
        return `${diffMins} mins ago`;
    } else {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[messageDate.getMonth()];
        const day = messageDate.getDate();
        return `${month} ${day}`;
    }
}


function LiveChat() {
    /**
     * Messages are in format:
     * [{id: int (message_id) sender: int (user_id), 
     * receiver: int (user_id), message: str, timestamp: YYYY-MM-DDTHH:MM:SS.1234Z}, ...]
     */
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [inputValue, setInputValue] = useState('');
    const gptID = 3
    const messagesEndRef = useRef(null);

    // Retrieve list of history message from server
    useEffect(() => {
        const fetchHistory = async () => {
            try {
                setIsLoading(true);
                const token = localStorage.getItem('token');
                const response = await ajax('/livechat/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok){
                    throw new Error('Failed to fetch History');
                }
                const data = await response.json();
                setMessages(data.results);
                setInputValue("")
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchHistory();
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [messages]); // Scroll to the bottom of the chat when a new message is added

    // Send a new messsage to server
    const sendMessage = async (message) => {
        // Add the message to Messages
        var newMessage = {
            id: -1,
            sender: -1,
            receiver: gptID,
            message: message,
            timestamp: new Date().toISOString()
        }
        setMessages([...messages, newMessage])
        setInputValue("")

        try {
            const token = localStorage.getItem('token');
            const response = await ajax('/livechat/create/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({message: message})
            });
            if (!response.ok){
                throw new Error('Failed to send message');
            }
            const data = await response.json();
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                updatedMessages.push(data.chatgpt_reply);
                return updatedMessages;
            });
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='live-chat'>
            <div className="live-chat__messages">
                {messages.map((message, index) => {
                    return (
                        <div key={index}>
                            {message.sender === gptID ?
                                <div key={index} className="live-chat__message" style={{justifyContent: "flex-start"}}>
                                    <div className="live-chat__message-sender-gpt">
                                        <img src={GPTicon} alt="Chat GPT Icon" />
                                    </div>
                                    <div className='live-chat__message_bubble' style={{}}>
                                        <div className="live-chat__message-content">{message.message}</div>
                                        <div className="live-chat__message-timestamp">{formatTimestamp(message.timestamp)}</div>
                                    </div>
                                </div> : (
                                <div key={index} className="live-chat__message" style={{justifyContent: "flex-end"}}>
                                    <div className='live-chat__message_bubble' style={{}}>
                                        <div className="live-chat__message-content">{message.message}</div>
                                        <div className="live-chat__message-timestamp">{formatTimestamp(message.timestamp)}</div>
                                    </div>
                                    <div className="live-chat__message-sender-user">
                                        <AccountCircleIcon fontSize='inherit'/>
                                    </div>
                                </div>
                                )
                            }
                        </div>
                    );
                })}
                <div ref={messagesEndRef} />
            </div>
            <div className='live-chat__input'>
                <input 
                    type="text" 
                    placeholder="Type a message..."
                    value={inputValue} 
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' ? sendMessage(inputValue) : ''}
                />
                <button className="live-chat__input-button" 
                onClick={() => sendMessage(inputValue)}
                >Send</button>
            </div>
        </div>
    );
}

export default LiveChat;
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ajax } from '../../ajax';
import './style.css';
import LoginIcon from '@mui/icons-material/Login';
import newYearImage from '../../imgs/new_year.jpg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// function Login() {
//     const [error, setError] = useState("");
//     const navigate = useNavigate();

//     function handle_submit(event) {
//         let data = new FormData(event.target);

//         ajax("/account/login/", {
//             method: "POST",
//             body: data,
//         })
//         .then(request => request.json())
//         .then(json => {
//             if ('access' in json) {
//                 localStorage.setItem('access', json.access);
//                 localStorage.setItem('username', data.get('username'));
//                 navigate('/');
//             }
//             else if ('detail' in json) {
//                 setError(json.detail);
//             }
//             else {
//                 setError("Unknown error while signing in.")
//             }
//         })
//         .catch(error => {
//             setError(error);
//         });

//         event.preventDefault();
//     }

//     return <form id="login" onSubmit={handle_submit}>
//         <h2>Please enter your login information</h2>
//         <label htmlFor="username">Username: </label>
//         <input type="text" id="username" name="username" required />
//         <label htmlFor="password">Password: </label>
//         <input type="password" id="password" name="password" required  />
//         <div className="offset-1">
//             <button className="btn" type="submit">Login</button>
//         </div>
//         <p className="error">{error}</p>
//     </form>;
// }

// export default Login;

function login() {
    return (
        <div className='login'>
            <div className='login__left'>
                <div className='login__title'>
                    <div className='login-icon'>
                        <LoginIcon fontSize='inherit'/>
                    </div>
                    <h4>Identity Verification</h4>
                </div>
                <div className='login__form'>
                    <form>
                        <div className='login__form-item'>
                            <label htmlFor='username'>IVN</label>
                            <input type='text' id='ivn' name='ivn' placeholder=''></input>
                        </div>
                        <div className='login__form-question'>
                            <div className='help-icon'>
                                <HelpOutlineIcon fontSize='inherit'/>
                            </div>
                            <p>What is IVN?</p>
                        </div>
                        <div className='login__form-submit'>
                            <input type='submit' value='Verify'></input>
                        </div>
                    </form>
                </div>
                <div className='footer'>
                    <p>Happy New Year Hanging Decorations Inside a Room taken by Anastasia Shuraeva. work licensed under CC BY 4.0</p>
                </div>
            </div>
            <div className='login__right'>
                <div className='login__image'>
                    <img src={newYearImage} alt='new year image'></img>
                </div>
            </div>
        </div>
    )
}

export default login;
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { motion } from "framer-motion";

const pageIndicatorVariants = (numLetters) => {
    var height = numLetters * 14.5;
    return {
      hidden: {
        y: 10,
        opacity: 1,
        transition: {
          type: 'spring',
          duration: 0.5
        }
      },
      ready: {
        y: -100,
        opacity: 1,
        transition: {
          type: 'spring',
          duration: 0.5
        }
      },
      visible: {
        y: -(height/2 + 220),
        opacity: 1,
        transition: {
          type: 'spring',
          duration: 0.5
        }
      },
      passed: {
        y: -550,
        transition: {
          type: 'spring',
          duration: 0.5
        }
      }
    }
  };

function ScrollProgress({scrollToPage, currentPage, totalPages, pageName}) {
    const getStage = (pageId) => {
        if (pageId === currentPage) {
          return 'visible';
        } else if (pageId === currentPage + 1) {
          return 'ready';
        } else if (pageId < currentPage) {
          return 'passed';
        } else {
          return 'hidden';
        }
    };

    
    return (
        <motion.div className='main-page-progress'>
        <motion.div className='process-going-up' onClick={() => scrollToPage(currentPage - 1)}>
          <KeyboardArrowUpIcon fontSize="large"/>
        </motion.div>
        <motion.div className='process-going-down' onClick={() => scrollToPage(currentPage + 1)}>
          <KeyboardArrowDownIcon fontSize="large"/>
        </motion.div>
        <div className='process-page-nums'>
          {Array.from({length: totalPages}, (_, i) => i).map((pageId) => (
            <motion.p
              key={pageId}
              variants={pageIndicatorVariants(pageName[pageId].length)}
              initial='hidden'
              animate={getStage(pageId)}
              onClick={() => scrollToPage(pageId)}
            >{pageName[pageId]}</motion.p>
          ))}
        </div>
      </motion.div>
    )
}

export default ScrollProgress;
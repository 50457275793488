import {React, useEffect, useState} from 'react'
import './style.css'
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';

const textTrasition = {
    type: 'spring',
    stiffness: 400,
    mass: 0.5,
    damping: 50,
}

const labelVariants = {
    initial: {
        width: 38,
        transition: textTrasition,
    },
    hover: {
        width: 140,
        transition: textTrasition,
    },
    disabled: {
        width: 100,
    }
}

const labelText1Vraiants = {
    initial: {
        x: -150,
        transition: textTrasition,
    },
    hover: {
        x: 0,
        transition: textTrasition,
    }
}

const labelText2Vraiants = {
    initial: {
        x: 0,
        transition: textTrasition,
    },
    hover: {
        x: 137,
        transition: textTrasition,
    },
}

function LinkButton({ status, description, link }) {
    const statusText1 = 'CLICK TO NAVIGATE TO';
    const statusText2 = status ? 'LINK' : 'COMING SOON';
    const [hoverStatus, setHoverStatus] = useState(false);

    const getCurrentStatus = () => {
        if (!status){
            return 'disabled';
        } else if (hoverStatus) {
            return 'hover';
        } else {
            return 'initial';
        }
    }

    return (
        <motion.div
            onHoverStart={() => setHoverStatus(true)}
            onHoverEnd={() => setHoverStatus(false)}
        >
            <Link to={link} className='link_button'>
                <motion.div className='link_button__label'
                    style={status === true ? {backgroundColor: "#735cff"} : {backgroundColor: "#ff5c5c"}}
                    variants={labelVariants}
                    animate={getCurrentStatus()}
                >
                    <motion.p
                    variants={labelText1Vraiants}
                    animate={getCurrentStatus}>
                        {status ? statusText1 : ''}
                    </motion.p>
                    <motion.p
                    variants={labelText2Vraiants}
                    animate={getCurrentStatus}>
                        {statusText2}
                    </motion.p>
                </motion.div>
                <div className='link_button__description'>
                    <p>{description}</p>
                </div>
            </Link>
        </motion.div>
    )
}

export default LinkButton
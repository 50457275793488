import './style.css';
import { useRef, useState } from 'react';
import { 
    motion, 
    useScroll, 
    useSpring, 
    useTransform, 
    useMotionValue,
    useVelocity,
    useAnimationFrame } from 'framer-motion';
import { wrap } from '@motionone/utils';

/**This code is modified based on Framer motion official Tutorial
 * https://www.framer.com/motion/scroll-animations/##scroll-velocity
 */

function ParallaxText({children, baseVelocity, fontSize = 40}) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });

    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);
    if (velocityFactor.get() < 0) {
        directionFactor.current = -1;
      } else if (velocityFactor.get() > 0) {
        directionFactor.current = 1;
      }
  
      moveBy += directionFactor.current * moveBy * velocityFactor.get();
  
      baseX.set(baseX.get() + moveBy);
    });

    return (
        <div className="parallax">
          <motion.div className="parallax_scroller noselect" style={{ x, fontSize: `${fontSize}px` }}>
            <span>{children} </span>
            <span>{children} </span>
            <span>{children} </span>
            <span>{children} </span>
          </motion.div>
        </div>
      );
}

export default ParallaxText;
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './pages/login';
import Landing from './pages/landing';
import Contact from './pages/contact';

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Landing />} />
        <Route path="/login/" exact element={<Login />} />
        <Route path="contact/" element={<Contact />} />
      </Route>
    </Routes>
  </BrowserRouter>;
}

export default App;

import { useNavigate, Outlet, Link } from "react-router-dom";
import './style.css';
import * as React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {motion} from 'framer-motion';
import NavigationPage from "../Navigation";
import { useLocation } from 'react-router-dom'
import icon from '../../imgs/logo_192.png';

const sideNavButtonVariants = {
    hover: {
        backgroundColor: "#d6d6d6",
    }
}

function Layout() {
    const location = useLocation();

    return <>
        {/* <NavigationPage /> */}
        <Link to="/" className="side-nav-logo">
            <img src={icon}/>
        </Link>
        <div className="side-nav">
            <div className="side-menu-btn"></div>
            <div className="side-buttons-lst">
                <motion.div className="side-avatar" 
                    variants={sideNavButtonVariants}
                    whileHover="hover">
                    {
                        location.pathname === '/login' ? 
                        <Link to="/">
                            <AccountCircleIcon fontSize="inherit"/>
                        </Link> :
                        <Link to="login">
                            <AccountCircleIcon fontSize="inherit"/>
                        </Link>
                    }
                </motion.div>
                <div className="side-gap"></div>
                <motion.div className="side-linkedin"
                    variants={sideNavButtonVariants}
                    whileHover="hover">
                    <Link>
                        <LinkedInIcon fontSize="inherit"/>
                    </Link>
                </motion.div>
                <motion.div className="side-github"
                    variants={sideNavButtonVariants}
                    whileHover="hover">
                    <Link>
                        <GitHubIcon fontSize="inherit"/>
                    </Link>
                </motion.div>
                <div className="side-gap"></div>
                <motion.div className="side-contact"
                    variants={sideNavButtonVariants}
                    whileHover="hover">
                    {
                    location.pathname === '/contact' ? 
                    <Link to="/" className="side-contact-in">
                        <p>CONTACT</p>
                    </Link> :
                    <Link to="contact" className="side-contact-in">
                        <p>CONTACT</p>
                    </Link>
                    }
                </motion.div>
            </div>
        </div>
        <main>
            <Outlet />
        </main>
    </>;
}

export default Layout;